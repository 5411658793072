const dataTable = {
  top: {
    filter_clear: "Clear",
    placeholder:
      'Enter search keywords in the format of Search Field = "Value"',
  },
  table: {
    header: {
      name: "Name",
      date: "Date",
      domain: "Domain",
      title: "Title",
      tag: "Tag",
      detection_info: "Detection Info",
      resource_num: "Resources",
      link_num: "Links",
      country: "Country",
      published_date: "Published Date",
      language: "Language",
      elapsed: "Elapsed",
      message: "Message",
      classification: "Classification",
      url_count: "URL Count",
      ip: "IP",
      source: "Source",
      id: "ID",
      url: "URL",
      api_key: "API Key",
      start_date: "Start Date",
      end_date: "End Date",
      status: "Status",
      error: "Error",
      req_id: "Request ID",
      url_id: "URL ID",
      favicon: "Favicon",
      headers: "Headers",
      screenshot: "Screenshot",
      page_url: "Page URL",
      anchor_text: "Anchor Text",
      selector: "Selector",
      data_md5: "MD5",
      type: "Type",
      content_type: "Content Type",
      size: "Size",
      resource_url: "Resource URL",
      source_id: "Source ID",
      judgement_basis: "Judgement Basis",
      target: "Target",
      permutation: "Permutation",
      disabled: "Disabled",
      comment: "Comment",
      version: "Version",
      description: "Description",
      registrant_name: "Registrant Name",
      registrant_address: "Registrant Address",
      registered_date: "Registered Date",
      updated_date: "Updated Date",
      expiration_date: "Expiration Date",
      authorized_agency: "Authorized Agency",
      dns_sec: "DNS SEC",
      dynamic_dns: "Dynamic DNS",
      email: "Email",
      link: "Link",
      actor: "Actor",
      action: "Action",
      geo_ip: "Geo IP",
      user_info: "User Info",
      data_sha256: "SHA256",
      host: "Host",
      app_url: "App URL",
      redirect_with_js: "JS",
      csr: "CSR",
    },
    empty: "No search results.",
    loading: "Loading data. Please wait.",
    current_page_report: "Showing {first} to {last} of {totalRecords}",
  },
};

export default dataTable;
