import {
  LinkRowsInfo,
  ResourceInfoRowsInfo,
  ScanResult,
  ScanResultRowsInfo,
  ScanSummaryResult,
  SearchParam,
  UrlRequest,
  DetectionInfo,
  JudgmentBasis,
  AddManualScannerRequest,
} from "@/types/search";
import { GetError, RestClient } from "./restclient";
import { AxiosHeaderValue, AxiosResponseHeaders } from "axios";
import { useAuthStore } from "@/store/auth";

export const summaryApi = {
  GetScanSummaryResultByUrlId: async (
    url_id: string
  ): Promise<[number, ScanSummaryResult | string]> => {
    try {
      const apikey = useAuthStore().access_id;
      let config = {};
      if (apikey) {
        config = {
          headers: {
            "X-APIKEY": apikey,
          },
        };
      }
      const { data, status } = await RestClient.get<ScanSummaryResult>(
        `/scanresults/${url_id}/summary`,
        config
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetJudgmentBasisByUrlId: async (
    url_id: string
  ): Promise<[number, JudgmentBasis | string]> => {
    try {
      const [status, data] = await summaryApi.GetScanSummaryResultByUrlId(
        url_id
      );
      if (typeof data === "string") {
        // If there's an error message
        return [status, data];
      }
      const judgmentBasis = data.judgment_basis;
      return [status, judgmentBasis];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetScreenshotByUrlId: async (
    url_id: string
  ): Promise<
    [
      number,
      string,
      (
        | AxiosResponseHeaders
        | Partial<{
            Server: AxiosHeaderValue;
            "Content-Type": AxiosHeaderValue;
            "Content-Length": AxiosHeaderValue;
            "Cache-Control": AxiosHeaderValue;
            "Content-Encoding": AxiosHeaderValue;
          }>
      )
    ]
  > => {
    try {
      const { data, status, headers } = await RestClient.get<string>(
        `/scanresults/${url_id}/screenshot`,
        {
          responseType: "blob",
        }
      );
      return [status, data, headers];
    } catch (e: unknown) {
      const errorCode = 500;
      const errorMessage = "An error occurred";
      const errorHeaders = {};
      return [errorCode, errorMessage, errorHeaders];
    }
  },

  //스크린샷 수동 수정
  UpdateScreenshot: async (
    url_id: string,
    formData: FormData
  ): Promise<[number, any]> => {
    const { status, data } = await RestClient.post(
      `/scanresults/${url_id}/screenshot`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return [status, data];
  },

  //리스크린샷 데이터 가져오기
  RescreenShot: async (
    url_id: string,
    body: { timeout: number }
  ): Promise<[number, any]> => {
    const { status, data } = await RestClient.post(
      `/scanresults/${url_id}/rescreenshot`,
      body,
      {
        responseType: "blob",
        headers: {
          Accept: "image/*",
          "Content-Type": "application/json",
        },
      }
    );
    return [status, data];
  },

  // 스크린샷 삭제
  DeleteScreenshot: async (url_id: string): Promise<[number, any]> => {
    const { status, data } = await RestClient.delete(
      `/scanresults/${url_id}/screenshot`
    );
    return [status, data];
  },

  GetResourcesByUrlId: async (
    sp: SearchParam
  ): Promise<[number, ResourceInfoRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ResourceInfoRowsInfo>(
        `/resources/search`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  // URL redirects 수정
  PatchUrlRedirects: async (
    id: string,
    request: { urls: string[] }
  ): Promise<[number, ScanSummaryResult | string]> => {
    try {
      const { data, status } = await RestClient.patch<ScanSummaryResult>(
        `/scanresults/${id}/redirects`,
        request
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  SearchResources: async (
    id: string,
    sp: SearchParam
  ): Promise<[number, ResourceInfoRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ResourceInfoRowsInfo>(
        `/scanresults/${id}/resources`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  GetLinksByUrlId: async (
    url_id: string,
    sp: SearchParam
  ): Promise<[number, LinkRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<LinkRowsInfo>(
        `/scanresults/${url_id}/links`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetRealtimeScanResult: async (): Promise<[number, ScanResult[] | string]> => {
    try {
      const { data, status } = await RestClient.get<ScanResult[]>(
        `/urls/latest`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  SearchScanResultByResource: async (
    sp: SearchParam
  ): Promise<[number, ScanResultRowsInfo | string]> => {
    try {
      const { data, status } = await RestClient.post<ScanResultRowsInfo>(
        `/urls/search/resources`,
        sp
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetResourceHashInfo: async (
    hashId: string
  ): Promise<
    [number, { size: number; content_type: string; type: string } | string]
  > => {
    try {
      const { data, status } = await RestClient.get<{
        size: number;
        content_type: string;
        type: string;
      }>(`/urls/resources/${hashId}`);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetUrlStatus: async (
    req: UrlRequest
  ): Promise<[number, boolean | string]> => {
    try {
      const { data, status } = await RestClient.post<boolean>(
        `/scanresults/url/status`,
        req
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetWhitelistInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/whitelist/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetBlacklistInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/blacklist/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetPermutationInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/permutation/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetPatternInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/patterns/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetScanResultInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/scanresults/${itemId}`
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetScrapeOptionInfo: async (
    itemId: string
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.get<DetectionInfo>(
        `/scrapeopts/${itemId}`
      );
      console.log(data);
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  GetResourceData: async (
    itemId: string
  ): Promise<[number, ArrayBuffer | string]> => {
    try {
      const { data, status } = await RestClient.get<ArrayBuffer>(
        `/resources/${itemId}/data`,
        {
          responseType: "arraybuffer",
        }
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },

  PatchJudgementBasis: async (
    itemId: string,
    judgement: { en: string; ja: string; ko: string; zh: string }
  ): Promise<[number, DetectionInfo | string]> => {
    try {
      const { data, status } = await RestClient.patch<DetectionInfo>(
        `/scanresults/${itemId}/judgmentbasis`,
        judgement // judgement 객체를 그대로 보냅니다.
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
  AddManualScanner: async (
    url_id: string,
    request: AddManualScannerRequest
  ): Promise<[number, any | string]> => {
    try {
      const { data, status } = await RestClient.post<any>(
        `/scanresults/${url_id}/scanner/manual`,
        request
      );
      return [status, data];
    } catch (e: unknown) {
      return GetError(e);
    }
  },
};
