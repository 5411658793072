const dataTable = {
  top: {
    filter_clear: "初期化",
    placeholder: 'Search Field = "Value" と入力して検索してください',
  },
  table: {
    header: {
      name: "名前",
      date: "日付",
      domain: "ドメイン",
      title: "タイトル",
      tag: "タグ",
      detection_info: "検出情報",
      resource_num: "リソース",
      link_num: "リンク",
      country: "国",
      published_date: "投稿日",
      language: "言語",
      elapsed: "経過時間",
      message: "メッセージ",
      classification: "分類",
      url_count: "URL 数",
      ip: "IP",
      source: "ソース",
      id: "ID",
      url: "URL",
      api_key: "APIキー",
      start_date: "開始日",
      end_date: "終了日",
      status: "状態",
      error: "エラー",
      req_id: "リクエストID",
      url_id: "URL ID",
      favicon: "ファビコン",
      headers: "ヘッダ",
      screenshot: "画面撮影",
      page_url: "ページURL",
      anchor_text: "アンカーテキスト",
      selector: "セレクタ",
      data_md5: "MD5",
      data_sha256: "SHA256",
      type: "タイプ",
      content_type: "コンテンツタイプ",
      size: "サイズ",
      resource_url: "リソースURL",
      source_id: "ソースID",
      judgement_basis: "判断根拠",
      target: "対象",
      permutation: "パーミュテーション",
      disabled: "無効",
      comment: "コメント",
      version: "バージョン",
      description: "説明",
      registrant_name: "登録者",
      registrant_address: "登録者住所",
      registered_date: "登録日",
      updated_date: "更新日",
      expiration_date: "有効期限",
      authorized_agency: "認可機関",
      dns_sec: "DNS SEC",
      dynamic_dns: "ダイナミックDNS",
      email: "Eメール",
      link: "リンク",
      actor: "アクター",
      action: "アクション",
      geo_ip: "地理IP",
      user_info: "ユーザー情報",
      host: "ホスト",
      app_url: "アプリ URL",
      redirect_with_js: "JS",
      csr: "CSR",
    },
    empty: "検索結果がありません。",
    loading: "データを読み込んでいます。しばらくお待ちください。",
    current_page_report: "全体{totalRecords}件中{first}~{last}番目のデータ出力",
  },
};

export default dataTable;
