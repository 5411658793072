const search = {
  tab: {
    sms: "SMS Messages",
    qr: "QR Code(Image)",
  },
  summary: {
    analysis: "Analysis",
    scan_at: "Last scan date",
    status: "Status",
    tags: "Tags",
    issuer: "Issuer",
    subject: "Subject",
    not_before: "Date of issue",
    not_after: "Expiration date",
    version: "TLS Version",
    judgment_basis: "Basis for final judgment(By ‘Generative AI’)",
    analysis_by_engine: "Analysis results and judgment basis for each engine",
    detection: "Detection Information by Engine",
    detection_invaild_id: "The item ID information is invalid.",
    screenshot: "Screenshot",
    doamin_title: "Domain",
    domain: "Domain Name",
    domain_info: "Domain Information",
    registered_info: "Registrant Information",
    administrative_info: "Administrative Information",
    administrative_name: "Administrator",
    phone: "Phone Number",
    post_code: "Postal Code",
    geo_ip_info: "Geo IP Information",
    registered_date: "Registration date",
    registrant: "Registrant",
    registrant_address: "Registrant address",
    updated_date: "Updated date",
    expiration_date: "Expiration date",
    name_servers: "Name server",
    ip_address: "ip address",
    nation: "Nation",
    host_name: "Host name",
    screenshot_none: "There are no screenshot images.",
    geo_ip_none: "Geo IP information does not exist.",
    host_ip_none: "Host IP information does not exist.",
    domain_none: "Domain information does not exist.",
    certificate_none: "Certificate information does not exist.",
    detection_none: "Detection information does not exist.",
    hash_none: "Hash information does not exist.",
    certificate_title: "Certificate",
    show_brief: "Show Brief",
    show_all: "Show All",
    summary: "Summary",
    resources: "Resources",
    links: "Links",
    whitelist_add: "Add WL",
    manual_add: "Add Manual",
    classification: {
      phishing: "phishing",
      fraud: "fraud",
      gambling: "gambling",
      adult: "adult",
      malware: "malware",
      illegal: "illegal",
      normal: "normal",
      inaccessible: "inaccessible",
      unknown: "unknown",
      privacy: "privacy",
    },
  },
  resources: {
    tips: "It shows the Domain (URL) information containing the hash file.",
    resource_info: "Resource Information",
  },
  scanning: {
    url_scanning: "Scanning URL.",
    wait_a_moment: "please wait for a moment.",
  },
  audit: {
    title: "Audit Log",
    changes: "Changes",
  },
  news: {
    enter_title: "Please enter title",
    enter_link: "Please enter link",
    enter_published_date: "Please enter published date",
    enter_source: "Please enter source",
    enter_lang: "Please select language",
  },
  detection: {
    enter_item_id: "Please enter the ID.",
    enter_tags: "Please select tags.",
    enter_description: "Please enter the description.",
    enter_domain: "Please enter the domain.",
    enter_url: "Please enter the URL.",
    enter_source: "Please enter the source.",
    enter_source_id: "Please enter the source ID.",
    enter_classification: "Please enter the classification.",
    enter_judgment_basis: "Please enter the judgment basis.",
    enter_target: "Please enter the target.",
    enter_host: "Please enter the host.",
    include_subdomain: "Include subdomain",
    whitelist_judgment_basis:
      "There is no clear evidence of malicious activity or phishing. Therefore, it is determined to be a normal site.",
  },
  update: {
    before: "Before Update",
    after: "After Update",
    screenshot_dialog_title: "Edit Screenshot",
    screenshot_dialog_no_image: "No image available",
    screenshot_dialog_select_image: "Please select an image",
    screenshot_dialog_choose_image: "Select Image",
    screenshot_dialog_confirm_download: "Would you like to download the image?",
    screenshot_dialog_drop_image: "Or drop the image here.",
    no_file_selected: "No file selected",
  },
  support: {
    process_time: "Processing Time",
    title: "NICE Inquiry",
    assignee: "Assignee",
    assign_to_me: "Assign to Me",
    status: "Status",
    status_wait: "Waiting",
    status_done: "Completed",
    status_cancel: "Cancelled",
    change_to_cancel: "Change to Cancel",
    change_to_wait: "Change to Waiting",
    email: "Email",
    inquiry_type: "Inquiry Type",
    transaction_id: "Transaction ID",
    inquiry_date: "Inquiry Date",
    inquiry_content: "Inquiry Content",
    write_answer: "Write Answer",
    cancel: "Cancel",
    send: "Send",
    answer_time: "Answer Time",
    answer_content: "Answer Content",
    write_comment: "Write Comment",
    comment: "Comment",
    enter_comment: "Enter comment",
    enter_scan_result_url: "Enter the scan result URL.",
    report_url: "Report URL",
    contact_url: "Contact URL",
    type: {
      contact: "Inquiry Type",
      nice: "Inquiry Type",
      report: "Report Type",
    },
    contents: {
      contact: "Inquiry Content",
      nice: "Inquiry Content",
      report: "Report Content",
    },
    dialog_title: {
      contact: "Inquiry",
      report: "Report",
      nice: "NICE Support",
    },
    company_name: "Company",
    department: "Department",
    name: "Name",
    mobile: "Mobile",
    product_name: "Product",
    unassigned: "Unassigned",
  },
  domain: {
    domain_add: "Add Domain",
    domain_update: "Update Domain",
    domain_view: "View Domain",
    authorized_agency: "Enter authorized agency",
    dns_sec: "Enter DNS Sec",
    name_servers: "Enter name servers (separated by ,)",
    status: "Enter status (separated by ,)",
    registrant: {
      name: "Enter registrant name",
      address: "Enter registrant address",
      postcode: "Enter registrant postal code",
    },
    administrative: {
      name: "Enter administrative contact name",
      email: "Enter administrative contact email",
      phone: "Enter administrative contact phone",
    },
  },
};

export default search;
