const dashboard = {
  title: {
    scan_request: "スキャンリクエスト",
    top_url: "リクエストURLランキング",
    map: "国別データ",
    lastest_blog: "最新のブログ",
    detection_rate: "検出率",
    false_positive: "誤検出率",
    false_negative: "未検出率",
    collection_status: "収集状況",
    collection: "収集",
    pattern_top: "パターン検出順位",
    detection_status_total: "全体検出状況",
    detection_status: "探知現況",
  },
  period: {
    day: "1日",
    week: "1週間",
    month: "1ヶ月",
    year: "1年",
  },
  time: {
    hour: "時",
    day: "日",
    month: "月",
  },
  chart: {
    requestStatus: "リクエスト状況",
    totalRequests: "総リクエスト",
    requests: "件",
    added_count: "追加件数",
    total_added: "総追加件数",
  },
  table: {
    rank: "順位",
    url: "URLアドレス",
    count: "回",
    country: "国",
    percentage: "占有率",
    pattern_id: "パターンID",
  },
  weekdays: ["月", "火", "水", "木", "金", "土", "日"],
  no_data: "データがありません",
  no_data_retrieved: "データが見つかりません",
  average: "平均",
  detection: "検知",
  false_negative: "未検知",
  false_positive: "誤検知",
  others: "その他",
};

export default dashboard;
