const scrapeRoutes = [
  {
    path: "/scrape/url",
    name: "urlscrape",
    component: () =>
      import(
        /* webpackChunckName: "urlrequest" */ "@/views/scrape/UrlScrapeView.vue"
      ),
  },
  {
    path: "/scrape/link",
    name: "linkscrape",
    component: () =>
      import(
        /* webpackChunckName: "smsrequest" */ "@/views/scrape/LinkScrapeView.vue"
      ),
  },
  {
    path: "/scrape/resource",
    name: "resourcescrape",
    component: () =>
      import(
        /* webpackChunckName: "smsrequest" */ "@/views/scrape/ResourceScrapeView.vue"
      ),
  },
];

export default scrapeRoutes;
