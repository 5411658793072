const dashboard = {
  title: {
    scan_request: "Scan Requests",
    top_url: "Requested URL Rank",
    map: "Country Scan Status ",
    lastest_blog: "Latest Blog",
    detection_rate: "Detection Rate",
    false_positive: "False Positive Rate",
    false_negative: "False Negative Rate",
    collection_status: "Collection Status",
    collection: "Collection",
    pattern_top: "Pattern Detection Rank",
    detection_status_total: "Total Detection Status",
    detection_status: "Detection Status",
  },
  period: {
    day: "1 day",
    week: "1 week",
    month: "1 month",
    year: "1 year",
  },
  time: {
    hour: "h",
    day: "d",
    month: "m",
  },
  chart: {
    requestStatus: "Request Status",
    totalRequests: "Total Requests",
    requests: "requests",
    added_count: "Added Count",
    total_added: "Total Added Count",
  },
  table: {
    rank: "Rank",
    url: "URL Address",
    count: "count",
    country: "Country",
    percentage: "Percentage",
    pattern_id: "Pattern ID",
  },
  weekdays: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  no_data: "No data available.",
  no_data_retrieved: " No data available",
  average: "Avg",
  detection: "Detection",
  false_negative: "False Negative",
  false_positive: "False Positive",
  others: "Others",
};

export default dashboard;
